.animated-text {
    font-size: 4rem;
    color: #4a90e2;
    text-shadow: 0 0 10px rgba(74, 144, 226, 0.7);
    margin-bottom: 20px;
    display: inline-block;
    position: relative;
  }
  
  .neon-letter {
    position: relative;
    display: inline-block;
  }
  
  .trail {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background: radial-gradient(circle, rgba(74, 144, 226, 0.8) 0%, rgba(74, 144, 226, 0) 70%);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: trailFade 1s ease-out;
    pointer-events: none;
  }
  
  @keyframes trailFade {
    0% {
      width: 100px;
      height: 100px;
      opacity: 0.8;
    }
    100% {
      width: 200px;
      height: 200px;
      opacity: 0;
    }
  }
  
  .neon-letter {
    color: #fff;
    text-shadow:
      0 0 5px #fff,
      0 0 10px #fff,
      0 0 20px #fff,
      0 0 40px #4a90e2,
      0 0 80px #4a90e2,
      0 0 90px #4a90e2,
      0 0 100px #4a90e2,
      0 0 150px #4a90e2;
  }