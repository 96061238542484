@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Orbitron', sans-serif;
  background-color: #000;
  color: #fff;
  height: 100%;
  overflow: hidden;
}

.app {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

.stars, .twinkling {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.stars {
  background:#000 url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;
  z-index: 0;
}

.twinkling{
  background:transparent url(http://www.script-tutorials.com/demos/360/images/twinkling.png) repeat top center;
  z-index: 0;
  animation: move-twink-back 200s linear infinite;
}

.nav {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.nav button {
  background: none;
  border: none;
  color: #4a90e2;
  font-family: 'Orbitron', sans-serif;
  font-size: 1.2rem;
  margin-left: 20px;
  cursor: pointer;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.nav button:hover {
  color: #ffffff;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

.section-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

h1 {
  font-size: 4rem;
  color: #4a90e2;
  margin-bottom: 10px;
  text-shadow: 0 0 10px rgba(74, 144, 226, 0.7);
}

h2 {
  font-size: 3rem;
  color: #4a90e2;
  margin-bottom: 20px;
  text-shadow: 0 0 10px rgba(74, 144, 226, 0.7);
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #e0e0e0;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
}

/* ... (previous CSS remains the same) ... */

.animated-text {
    font-size: 4rem;
    color: #4a90e2;
    text-shadow: 0 0 10px rgba(74, 144, 226, 0.7);
    margin-bottom: 20px;
    display: inline-block;
  }
  
  .animated-text span {
    display: inline-block;
    opacity: 0;
    transform: translateY(20px);
  }
  
  .animated-text span::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -5px;
    left: 0;
    background-color: #4a90e2;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .animated-text:hover span::after {
    transform: scaleX(1);
  }
  
  /* ... (previous CSS remains the same) ... */

.animated-text {
    font-size: 4rem;
    color: #4a90e2;
    text-shadow: 0 0 10px rgba(74, 144, 226, 0.7);
    margin-bottom: 20px;
    display: inline-block;
    position: relative;
  }
  
  .flying-letter {
    display: inline-block;
    position: relative;
  }
  
  .light-trail {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background: radial-gradient(circle, rgba(74, 144, 226, 0.8) 0%, rgba(74, 144, 226, 0) 70%);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: trailFade 3s ease-out;
    pointer-events: none;
  }
  
  @keyframes trailFade {
    0% {
      width: 100px;
      height: 100px;
      opacity: 0.8;
    }
    100% {
      width: 200px;
      height: 200px;
      opacity: 0;
    }
  }
  
  /* ... (rest of the CSS remains the same) ... */
  /* ... (rest of the CSS remains the same) ... */
/* ... (previous CSS remains the same) ... */

.projects-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 30px;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

.project-card {
  background-color: rgba(26, 26, 26, 0.8);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid rgba(74, 144, 226, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  padding: 10px;
}

.project-card:hover {
  margin: 0;
  padding: 20px;
  box-shadow: 0 0 20px rgba(74, 144, 226, 0.5);
}

.project-card h3 {
  font-size: 1.2rem;
  color: #4a90e2;
  margin-bottom: 10px;
  text-align: center;
}

.project-card canvas {
  width: 100% !important;
  height: 150px !important;
  transition: all 0.3s ease;
}

.project-card:hover canvas {
  height: 170px !important;
}

/* ... (rest of the CSS remains the same) ... */

  .project-card p {
    font-size: 0.9rem;
    color: #bbb;
    flex-grow: 1;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .projects-grid {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 900px) {
    .projects-grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }
  }
  
  @media (max-width: 600px) {
    .projects-grid {
      grid-template-columns: 1fr;
    }
    
    .project-card:hover {
      margin: 5px;
      padding: 25px;
    }
  }
  
  /* ... (rest of the CSS remains the same) ... */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #1a1a1a;
  padding: 30px;
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
}

.modal-content h2 {
  font-size: 2.5rem;
  color: #4a90e2;
  margin-bottom: 20px;
}

.modal-content p {
  margin-bottom: 20px;
}

.close-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.close-button:hover {
  background-color: #3a7cd0;
  transform: scale(1.05);
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 20px 0;
  color: #888;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  transition: opacity 0.3s ease;
  opacity: 0;
}

footer.visible {
  opacity: 1;
}


@media (max-width: 768px) {
  h1 {
    font-size: 3rem;
  }
  
  h2 {
    font-size: 2.5rem;
  }
  
  .projects-grid {
    grid-template-columns: 1fr;
  }
}